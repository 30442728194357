import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { PATH } from 'hnap/hnapConst';
import HnapUserContext from 'hnap/utils/store/hnapUserContext';

interface ProtectedRouteProps {
  serviceId: string;
  path: string;
  children: React.ReactNode;
}

const ProtectedRoute = ({
  serviceId,
  path,
  children,
}: ProtectedRouteProps): JSX.Element => {
  const {
    term: { isContacted },
    service: { serviceIds },
  } = useContext(HnapUserContext);

  if (!isContacted)
    return <Redirect to={`${PATH.HNAP.ROOT}${PATH.HNAP.TERM}`} />;
  if (!serviceIds || !serviceIds.includes(serviceId))
    return <Redirect to={`${PATH.HNAP.ROOT}${PATH.HNAP.SERVICE_LIST}`} />;

  return <Route path={path}>{children}</Route>;
};

export default ProtectedRoute;
