/**
 *  共有部品定義ファイル
 * (@typesに方宣言が存在するオブジェクトを定義)
 *
 */

import { MENU, PATH, STRAGE_ID } from 'hnap/hnapConst';
import { Menu } from 'hnap/@types';

/**
 *  アプリメニュー用
 */
const REQUEST_URL = process.env.REACT_APP_REQUEST_URL ?? '';
export const MENUS: Menu[] = [
  {
    type: 'link',
    title: MENU.TITLE.TOP,
    path: '/',
  },
  {
    type: 'pdf',
    title: MENU.TITLE.HINO_APP_HOW_TO_USE,
    path: `${REQUEST_URL}${PATH.API.DOC}${STRAGE_ID.HINO_APP_HOW_TO_USE_PDF}`,
  },
  {
    type: 'pdf',
    title: MENU.TITLE.TERMS_OF_USE,
    path: `${REQUEST_URL}${PATH.API.DOC}${STRAGE_ID.TERMS_OF_USE_PDF}`,
  },
  {
    type: 'pdf',
    title: MENU.TITLE.PRIVACY_POLICY,
    path: `${REQUEST_URL}${PATH.API.DOC}${STRAGE_ID.PRIVACY_POLICY_PDF}`,
  },
  {
    type: 'pdf',
    title: MENU.TITLE.INQUIRY,
    path: `${REQUEST_URL}${PATH.API.DOC}${STRAGE_ID.INQUIRY_PDF}`,
  },
  {
    type: 'pdf',
    title: MENU.TITLE.FAQ,
    path: `${REQUEST_URL}${PATH.API.DOC}${STRAGE_ID.FAQ_PDF}`,
  },
  {
    type: 'link',
    title: MENU.TITLE.USER_MANAGEMENT,
    path: `${PATH.HNAP.ROOT}${PATH.HNAP.USER_LIST}`,
  },
  {
    type: 'logout',
    title: MENU.TITLE.LOGOUT,
    path: `${REQUEST_URL}${PATH.API.LOGOUT}`,
  },
];

export default {
  DANGER: 'danger',
  INVALID: 'invalid',
  PRIMARY: 'primary',
  BLACK: 'black',
  WHITE: 'white',
  GRAY: 'gray',
  DARK_GRAY: 'dark-gray',
};
