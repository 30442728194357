import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

import styles from 'hnap/views/components/Loading/Loading.module.scss';

type LoadingType =
  | 'blank'
  | 'balls'
  | 'bars'
  | 'bubbles'
  | 'cubes'
  | 'cylon'
  | 'spin'
  | 'spinningBubbles'
  | 'spokes';

interface LoadingProps {
  color?: string;
  height?: number | string;
  width?: number | string;
  delay?: number;
  type?: LoadingType;
  className?: string;
}

const loadingProps: LoadingProps = {
  color: '#999999',
  height: 100,
  width: 100,
  type: 'spokes',
};

const preventKeyDown = (event: KeyboardEvent) => {
  event.preventDefault();
};

const Loading: React.FC = () => {
  useEffect(() => {
    window.addEventListener('keydown', preventKeyDown);
    return () => window.removeEventListener('keydown', preventKeyDown);
  }, []);

  return (
    <div className={styles['loading-screen']}>
      <div className={styles['loading-icon']}>
        <ReactLoading {...loadingProps} />
      </div>
    </div>
  );
};

export default Loading;
