import React from 'react';
import styles from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'danger';
  className?: string;
  children: React.ReactNode;
}

const Button = (props: ButtonProps): JSX.Element => {
  const { className, children, color } = props;

  return (
    <button
      type='button'
      {...props}
      className={`${styles.root} ${className} ${
        color ? styles[`root--${color}`] : ''
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
