import axios from 'axios';

import {
  UserData,
  UserRegistInfo,
  UserUpdateInfo,
  UserRegistRequest,
  UserUpdateRequest,
  UserDeleteRequest,
  UpdateUserResponse,
} from 'hnap/utils/user';
import { TermsVersions } from 'hnap/@types';
import { PATH } from 'hnap/hnapConst';

const REQUEST_URL = process.env.REACT_APP_REQUEST_URL ?? '';
const SLEEP_TIME = process.env.REACT_APP_SLEEP_TIME ?? '0';

axios.defaults.withCredentials = true;

/**
 * サービスID取得API
 *
 * return statusCode
 * return serviceIds:契約サービスID配列
 */
export const fetchServiceId = async (): Promise<string[]> => {
  const response = await axios.get(`${REQUEST_URL}${PATH.API.SERVICE_ID}`);
  return response.data.serviceIds;
};

/**
 * 共通規約バージョン取得API
 *
 * return statusCode
 * return termsVersion:最新規約バージョン
 * return acceptedTermsVersion:同意済規約バージョン
 */
export const fetchContactVersion = async (): Promise<TermsVersions> => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1 && userAgent.indexOf('edge') === -1) {
    await new Promise((resolve) => setTimeout(resolve, Number(SLEEP_TIME)));
  }
  const response = await axios.get<TermsVersions>(
    `${REQUEST_URL}${PATH.API.TERMS_VERSION}`
  );
  return response.data;
};

/**
 * 規約更新API
 *
 * param serviceId:サービスID(共通:"0000")
 * param termsType:規約タイプ(利用規約:"0000")
 * param acpdTermsVersion:同意済規約バージョン
 *
 * return statusCode
 */
export const updateContactVersion = async (param: {
  serviceId: string;
  type: string;
  version: string;
}): Promise<void> => {
  const { serviceId, type, version } = param;
  const response = await axios.put(`${REQUEST_URL}${PATH.API.TERMS_INFO}`, {
    serviceId,
    termsType: type,
    acceptedTermsVersion: version,
  });
  return response.data;
};

export const openPdf = async (url: string): Promise<void> => {
  const response = await axios.get(url, { responseType: 'blob' });
  const openUrl = URL.createObjectURL(response.data);
  window.open(openUrl);
};

export const getUserData = async (): Promise<UserData> => {
  const response = await axios.get(`${REQUEST_URL}${PATH.API.USER_LIST}`);
  return response.data;
};

export const getUserRegistInfo = async (): Promise<UserRegistInfo> => {
  const response = await axios.get(
    `${REQUEST_URL}${PATH.API.USER_REGIST_INFO}`
  );
  return response.data;
};

export const getUserUpdateInfo = async (
  userId: string
): Promise<UserUpdateInfo> => {
  const response = await axios.get(
    `${REQUEST_URL}${PATH.API.USER_UPDATE_INFO}/${userId}`
  );
  return response.data;
};

export const registUser = async (
  registInfo: UserRegistRequest
): Promise<void> => {
  await axios.post(`${REQUEST_URL}${PATH.API.REGIST_USER}`, { ...registInfo });
};

export const updateUser = async (
  updateInfo: UserUpdateRequest
): Promise<UpdateUserResponse> => {
  const response = await axios.put(`${REQUEST_URL}${PATH.API.UPDATE_USER}`, {
    ...updateInfo,
  });

  return response.data;
};

export const deleteUser = async (users: UserDeleteRequest): Promise<void> => {
  await axios.put(`${REQUEST_URL}${PATH.API.DELETE_USER}`, { users });
};
