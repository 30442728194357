import React from 'react';

import Button from 'hnap/views/components/Button/Button';
import Form from 'hnap/views/components/Form/Form';

import { PATH } from 'hnap/hnapConst';
import styles from 'hnap/views/components/LogoutForm/LogoutForm.module.scss';

export interface LogoutFormProps {
  className?: string;
  onCancel(): void;
}

const LogoutForm = ({ className, onCancel }: LogoutFormProps): JSX.Element => {
  return (
    <Form className={`${styles.root} ${className}`}>
      <p className={styles.description}>ログアウトしますか？</p>
      <Button className={styles.cancel} onClick={onCancel}>
        キャンセル
      </Button>
      <a
        href={`${process.env.REACT_APP_REQUEST_URL}${PATH.API.LOGOUT}`}
        className={`${styles.submit}  ${styles['submit--primary']}`}
      >
        OK
      </a>
    </Form>
  );
};

export default LogoutForm;
