import React from 'react';
import { SERVICE } from 'const';

import styles from './Footer.module.scss';

interface FooterProps {
  className: string;
}

const Footer = ({ className }: FooterProps): JSX.Element => {
  return (
    <footer className={`${styles.root} ${className}`}>
      <small className={styles.copyright}>{SERVICE.copyright}</small>
    </footer>
  );
};

export default Footer;
