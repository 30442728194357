import React, { useContext, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import HnapAppContext from 'hnap/utils/store/hnapAppContext';

import PlainTemplate from 'hnap/views/components/PlainTemplate/PlainTemplate';
import Loading from 'hnap/views/components/Loading/Loading';
import ProtectedRoute from 'hnap/views/components/ProtectedRoute';

import './hnap/styles/index.scss';
import { PATH } from 'hnap/hnapConst';
import { SERVICE_IDS } from 'const';

const HnapRouter = lazy(() => import('hnap/HnapRouter'));
const SeapRouter = lazy(() => import('seap/SeapRouter'));

const App = (): JSX.Element => {
  const { isLoading } = useContext(HnapAppContext);

  return (
    <BrowserRouter>
      {isLoading && <Loading />}
      <PlainTemplate>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path='/' exact>
              <Redirect to={PATH.SEAP} />
            </Route>
            <Route path={PATH.HNAP.ROOT}>
              <HnapRouter />
            </Route>
            <ProtectedRoute path={PATH.SEAP} serviceId={SERVICE_IDS.SEAP}>
              <SeapRouter />
            </ProtectedRoute>
            <Route path='*'>
              <Redirect to={PATH.HNAP.ROOT} />
            </Route>
          </Switch>
        </Suspense>
      </PlainTemplate>
    </BrowserRouter>
  );
};

export default App;
