import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import App from 'App';
import HnapContextProvider from 'hnap/views/components/HnapContextProvider';
import theme from 'hnap/utils/theme/Theme';

ReactDOM.render(
  <HnapContextProvider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </HnapContextProvider>,
  document.getElementById('root')
);
