import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface CommonColors {
    red: string;
  }
}

const mainColor = '#de0029';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'white',
        backgroundColor: mainColor,
        '&:hover, &:active': {
          backgroundColor: mainColor,
          transform: 'scale(0.98)',
        },
      },
    },
  },
  palette: {
    common: {
      red: mainColor,
    },
  },
  typography: {
    fontFamily: "'Noto Sans JP', sans-serif",
  },
});

export default theme;
