import React, { useState } from 'react';

import { DefaultProps } from 'hnap/@types';
import { HnapAppProvider } from 'hnap/utils/store/hnapAppContext';
import { HnapUserProvider } from 'hnap/utils/store/hnapUserContext';

import Loading from './Loading/Loading';

const HnapContextProvider = ({ children }: DefaultProps): JSX.Element => {
  const [initialized, setInitialized] = useState(false);

  return (
    <HnapAppProvider>
      <HnapUserProvider onInitialized={setInitialized}>
        {initialized ? children : <Loading />}
      </HnapUserProvider>
    </HnapAppProvider>
  );
};

export default HnapContextProvider;
