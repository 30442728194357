/**
 *  定数値ファイル
 *
 */

import { Color, Service } from 'hnap/@types';
import logoImage from 'hnap/assets/logo.png';
import spLogoImage from 'hnap/assets/logo_sp.png';

export const SERVICE: Service = {
  name: 'HINO',
  version: process.env.REACT_APP_HNAP_VERSION ?? '',
  copyright: process.env.REACT_APP_HNAP_COPYRIGHT ?? '',
  logoImageUrl: logoImage,
  spLogoImageUrl: spLogoImage,
};

export const ROUTES = {
  INDEX: {
    label: '目次ページ',
    path: '/',
  },
  LOGOUT: {
    label: 'ログアウト',
    path: '/hnap/view/logout',
  },
  TERM: {
    label: '規約変更確認ページ',
    path: '/hnap/term',
  },
} as unknown as {
  [key: string]: {
    label: string;
    path: string;
  };
};
export const COLORS: Color = {
  DANGER: 'danger',
  INVALID: 'invalid',
  PRIMARY: 'primary',
  BLACK: 'black',
  WHITE: 'white',
  GRAY: 'gray',
  DARK_GRAY: 'dark-gray',
};

export const ROOT_PATH = {
  HNAP: '/hnap',
  SEAP: '/seap',
};

export const SERVICE_IDS = {
  SEAP: '0001',
};
