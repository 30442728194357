/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext } from 'react';

import { PATH } from 'hnap/hnapConst';
import HnapAppContext from './store/hnapAppContext';

export type ErrorMessage = {
  code: string;
  message: string;
};

type ResponseType<T> = {
  data?: T;
  errors?: ErrorMessage[];
};

const REQUEST_URL = process.env.REACT_APP_REQUEST_URL ?? '';
const errorPagePath = `${process.env.REACT_APP_AUTH_REQUEST_URL}${PATH.ERROR}`;

const useHttp = <T>(
  requestFunction: (...data: any) => Promise<T>
): ((...data: any) => Promise<ResponseType<T>>) => {
  const { setLoading } = useContext(HnapAppContext);

  const sendRequest = useCallback(
    async (...data: any) => {
      try {
        setLoading(true);
        const response = await requestFunction(...data);
        // console.table(response);
        setLoading(false);
        return {
          data: response,
          errors: undefined,
        };
      } catch (error: any) {
        setLoading(false);
        // console.table(error);

        if (error.message === 'Network Error') {
          window.location.href = `${REQUEST_URL}/hnap/view/login`;

          return {} as ResponseType<T>;
        }

        if (!error.response) {
          window.location.href = errorPagePath;
          return {} as ResponseType<T>;
        }

        const { status, data: message } = error.response;
        switch (status) {
          case 400:
            if (!message.errors) {
              window.location.href = errorPagePath;
              return {} as ResponseType<T>;
            }
            return {
              data: undefined,
              errors: message.errors,
            };
          case 500:
          default:
            window.location.href = errorPagePath;
            return {} as ResponseType<T>;
        }
      }
    },
    [setLoading, requestFunction]
  );

  return sendRequest;
};

export default useHttp;
