import React, { useContext } from 'react';

import HnapAppContext from 'hnap/utils/store/hnapAppContext';
import { SERVICE } from 'const';

import menuImage from 'hnap/assets/menu.svg';
import styles from 'hnap/views/components/Header/Header.module.scss';

interface HeaderProps {
  className: string;
  showMenu: boolean;
  onShowMenu(showMenu: boolean): void;
}

const Header = ({
  className,
  showMenu,
  onShowMenu,
}: HeaderProps): JSX.Element => {
  const { headerContent } = useContext(HnapAppContext);

  const content =
    typeof headerContent === 'string' ? (
      <h1 className={styles.name}>{headerContent}</h1>
    ) : (
      headerContent
    );

  return (
    <header className={`${styles.root} ${className}`}>
      <div className={styles.logo}>
        <img
          src={SERVICE.spLogoImageUrl}
          alt={SERVICE.name}
          className={styles.img__sp}
        />
        <img
          src={SERVICE.logoImageUrl}
          alt={SERVICE.name}
          className={styles.img__pc}
        />
      </div>
      {content}
      <button
        type='button'
        className={`${styles.menu} ${showMenu ? styles.menu__selected : ''}`}
        onClick={() => onShowMenu(!showMenu)}
      >
        <img src={menuImage} alt='' />
      </button>
    </header>
  );
};

export default Header;
