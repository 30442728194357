import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { SERVICE } from 'const';
import { Menu } from 'hnap/@types';
import LogoutForm from 'hnap/views/components/LogoutForm/LogoutForm';

import styles from 'hnap/views/components/SideMenu/SideMenu.module.scss';
import closeImage from 'hnap/assets/close.svg';
import Button from 'hnap/views/components/Button/Button';
import HnapUserContext from 'hnap/utils/store/hnapUserContext';

interface SideMenuProps {
  menus: Menu[];
  onShowMenu(showMenu: boolean): void;
  className: string;
}

Modal.setAppElement('#root');

const SideMenu = ({
  menus,
  onShowMenu,
  className,
}: SideMenuProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const {
    term: { isContacted },
  } = useContext(HnapUserContext);

  const historyBackHandler = () => setIsModalOpen(false);

  useEffect(() => {
    window.addEventListener('popstate', historyBackHandler);
    return () => window.removeEventListener('popstate', historyBackHandler);
  }, []);

  const handleShow = async (event: React.MouseEvent, docpath: string) => {
    event.preventDefault();
    window.open(docpath);
  };

  const onClickHandler = async (path: string) => {
    onShowMenu(false);

    if (path === '/') {
      window.location.assign('/');
      return;
    }

    history.push(path);
  };

  const menuItems = menus.map((menu) => {
    switch (menu.type) {
      case 'pdf':
        if (!isContacted) return null;
        return (
          <li key={menu.title}>
            <Button
              type='button'
              className={styles.show}
              color='primary'
              onClick={(event) => {
                onShowMenu(false);
                handleShow(event, menu.path);
              }}
            >
              <span>{menu.title}</span>
            </Button>
          </li>
        );
      case 'link':
        if (!isContacted) return null;
        return (
          <li key={menu.title}>
            <Button
              type='button'
              className={styles.show}
              color='primary'
              onClick={() => onClickHandler(menu.path)}
            >
              <span>{menu.title}</span>
            </Button>
          </li>
        );
      case 'logout':
        return (
          <li key={menu.title}>
            <a
              href={menu.path}
              target='_self'
              onClick={(event) => {
                event.preventDefault();
                onShowMenu(false);
                setIsModalOpen(true);
              }}
            >
              <span>{menu.title}</span>
            </a>
          </li>
        );
      default:
        return null;
    }
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.header}>
          <h1 className={styles.title}>ログアウト確認</h1>
          <button
            type='button'
            className={styles.close}
            onClick={() => setIsModalOpen(false)}
          >
            <img src={closeImage} alt='閉じる' />
          </button>
        </div>
        <div className={styles.body}>
          <LogoutForm onCancel={() => setIsModalOpen(false)} />
        </div>
      </Modal>
      <aside className={`${styles.root} ${className}`}>
        <ul>{menuItems}</ul>
        <span className={styles.version}>バージョン{SERVICE.version}</span>
      </aside>
    </React.Fragment>
  );
};

export default SideMenu;
