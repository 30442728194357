import React from 'react';

import styles from './Form.module.scss';

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  className: string;
  children: React.ReactNode;
  onInvalid?(e: React.FormEvent<HTMLFormElement>): void;
  onSubmit?(e: React.FormEvent<HTMLFormElement>): void;
}

const Form = (props: FormProps): JSX.Element => {
  const { className, children, onInvalid, onSubmit } = props;

  function handleOnInvalid(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (onInvalid) onInvalid(e);
  }
  function handleOnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (onSubmit) onSubmit(e);
  }

  return (
    <form
      {...props}
      className={`${styles.root} ${className}`}
      onInvalid={handleOnInvalid}
      onSubmit={handleOnSubmit}
    >
      {children}
    </form>
  );
};

export default Form;
