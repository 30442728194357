import React, { useState, useEffect } from 'react';

import { TermsVersions } from 'hnap/@types';
import useHttp from '../useHttp';
import { fetchContactVersion, fetchServiceId } from '../apiController';

interface HnapUserContextInterface {
  term: {
    versions: TermsVersions;
    isContacted: boolean;
    setVersions: (versions: TermsVersions) => void;
  };
  service: {
    serviceIds: string[] | undefined;
    setServiceIds: (ids: string[]) => void;
  };
}

const HnapUserContext = React.createContext<HnapUserContextInterface>(
  {} as HnapUserContextInterface
);

type HnapUserProviderProps = {
  children: React.ReactNode;
  onInitialized: (initialized: boolean) => void;
};

export const HnapUserProvider = ({
  children,
  onInitialized,
}: HnapUserProviderProps): JSX.Element => {
  const [versions, setVersions] = useState<TermsVersions>({
    termsVersion: null,
    acceptedTermsVersion: null,
  });
  const [serviceIds, setServiceIds] = useState<string[] | undefined>(undefined);

  const isContacted =
    !!versions.acceptedTermsVersion &&
    versions.termsVersion === versions.acceptedTermsVersion;

  const requestContactVersion = useHttp<TermsVersions>(fetchContactVersion);
  const requestServiceId = useHttp<string[]>(fetchServiceId);

  useEffect(() => {
    const setContactVersion = async () => {
      const { data: versions } = await requestContactVersion();

      if (!versions) {
        // onInitialized(true);
        return;
      }

      setVersions(versions);
      if (versions.termsVersion !== versions.acceptedTermsVersion) {
        onInitialized(true);
        return;
      }

      const { data: serviceIds } = await requestServiceId();
      if (serviceIds) setServiceIds(serviceIds);
      onInitialized(true);
    };
    setContactVersion();
  }, [requestContactVersion, requestServiceId, onInitialized]);

  const contextState: HnapUserContextInterface = {
    term: { versions, isContacted, setVersions },
    service: { serviceIds, setServiceIds },
  };

  return (
    <HnapUserContext.Provider value={contextState}>
      {children}
    </HnapUserContext.Provider>
  );
};

export default HnapUserContext;
